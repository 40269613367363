<template>
	<div id="tu-chat">
		<el-row :gutter="20">
			<el-col :span="4">
		    <SecondMenu />
			</el-col>
			<el-col :span="20">
				<div class="global-search">
					<div class="global-search__title">{{ $t('search') }}</div>
					<div class="global-search__warp">
						<el-form :inline="true" class="global-search__warp__inline">
							<el-form-item v-if="isStudent" :label="$t('select-teacher')">
								<el-select v-model="params.uid" placeholder="Select" clearable filterable>
									<el-option 
										v-for="item, index in teacherList"
										:key="item.uid"
										:label="item.nickname"
										:value="item.uid"
									/>
								</el-select>
							</el-form-item>
							<el-form-item v-else :label="$t('select-student')">								
								<el-select v-model="params.uid" placeholder="Select" clearable filterable>
									<el-option 
										v-for="item, index in studentList"
										:key="item.profile_id"
										:label="item.nickname"
										:value="item.uid"
									/>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="onSearchBtn">{{ $t('search') }}</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
				<!-- <div class="tu-title">
					<div>聊天数据</div>
					<div></div>
				</div> -->
				<div v-if="tableData.length > 0 || loading" v-loading="loading">
					<el-table 					
					element-loading-text="Loading..."
					:data="tableData" 
					border 
					:show-header="showHeader">
				    <el-table-column prop="preview" :label="$t('Preview')">
				    	<template #default="scope">
								{{ scope.row.content }}
							</template>
				    </el-table-column>
				    <el-table-column prop="name" :label="$t('Name')" width="180">
							<template #default="scope">
								<div>
									{{ scope.row.user_info.nickname }}
								</div>
							</template>
				    </el-table-column>
				    <el-table-column prop="date" :label="$t('date')" width="180">
				    	<template #default="scope">
				    		{{ scope.row.date_time }}
				    	</template>
				    </el-table-column>
				    <el-table-column width="120" align="center" :label="$t('Operations')">
				      <template #default="scope">
				        <el-button type="primary" size="mini" @click="handleDetail(scope.$index, scope.row)">
				        	{{ $t('detail') }}
				        </el-button>
				      </template>
				    </el-table-column>
					</el-table>	
					<div class="tu-pagination">
						<el-pagination 
							:hide-on-single-page="true" 
							background 
							layout="prev, pager, next" 
							:total="params.total"
							:page-size="20"
							@current-change="handlePageChange">
						</el-pagination>
					</div>
				</div>
				<div v-else>
					<el-empty :description="$t('No Data')"></el-empty>
				</div>
			</el-col>
		</el-row>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import SecondMenu from '@/components/SecondMenu'
import ChatApi from '@/api/chat'

export default {
	name: "chat",
	components: {
		SecondMenu
	},
	data() {
		return {
			loading: true,
			showHeader: true,
			tableData: [],
			params: {
				page: 1,
				total: 0,
				uid: null,
			}
		}
	},
	created() {
		this.getApiListData()
	},
	computed: {
		...mapGetters([
			'userInfo', 'userType', 'subjectData', 'studentList', 'teacherList', 'isStudent'
		]),
	},
	methods: {
		handleDetail(index, row) {
			let rowId = row.id;
			this.$router.push({ path: `/chat/${rowId}` })
		},
		handlePageChange(page) {
			this.loading = true
			this.params.page = page || 1;
			this.getApiListData();
		},
		getApiListData() {
			ChatApi.index(this.params).then((res) => {
				this.loading = false
				this.tableData = res.data
				this.params.total = res.meta.pagination.total
			});
		},
		onSearchBtn() {
			this.handlePageChange(1);
		}
	}
}
</script>
<style lang="less">
#tu-chat {
	.tu-title {
		background: var(--el-color-primary);
	  padding: 17px 15px;
	  color: var(--el-color-white);
	  text-align: left;
	  font-weight: 700;
	  display: flex;
	  flex-direction: row;
	  justify-content: space-between;
	  align-items: center;
	}

	.tu-pagination {
		padding: 20px 0;
	}
}
</style>