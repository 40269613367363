/**
 * 关于平台双方聊天方面的接口
 * 2021-11-21
 * author DuZeFang
 */
import request from '@/utils/request'

export default class ChatApi {
    // 消息列表
    static index(params) {
        return request({
            url: '/chat/index',
            method: 'post',
			data: params
        })
    }

    // 消息列表
    static info(id, params) {
        return request({
            url: `/chat/info/${id}`,
            method: 'post',
            data: params
        })
    }

    // 我想要聊天
    static want(id, params) {
        return request({
            url: `/chat/want/${id}`,
            method: 'post',
			data: params
        })
    }

    // 发送消息
    static put(chat_id, params) {
        return request({
            url: `/chat/put/${chat_id}`,
            method: 'post',
			data: params
        })
    }

    // 展示消息
    static show(chat_id, params) {
        return request({
            url: `/chat/show/${chat_id}`,
            method: 'post',
			data: params
        })
    }

    // 未读消息
    static wcount(params) {
        return request({
            url: '/chat/wcount',
            method: 'post',
			data: params
        })
    }

    // 标记聊天
    static star(chat_id, params) {
        return request({
            url: `/chat/star/${chat_id}`,
            method: 'post',
			data: params
        })
    }

    // 翻译
    static trans(id, params) {
        return request({
            url: `/chat/trans/${id}`,
            method: 'post',
			data: params
        })
    }
}